import React from 'react'

export default function LayerLegend(props) {

  return (
    <>
      <section className='content'>
        <div className='' style={{ position: 'absolute', top: '10px', left: 'auto', width: 'auto', zIndex: 1000, maxHeight: '700px', overflowY: 'auto', overflowX: 'hidden', }} >
          <div className="card">
            <div className="header">
              <a role="button" data-toggle="collapse" data-parent="#accordion_10" href="#collapseOne_10" aria-expanded="true" aria-controls="collapseOne_10" className='panel-title' onClick={(event) => {
              }} style={{ textDecoration: 'none' }}>
                <h2 className='text-muted'>
                  <i className="zmdi zmdi-eye text-muted"> &nbsp; </i>
                  {props.layer.Title}
                </h2>
              </a>
            </div>
            <div className="">
              <div className="row clearfix">
                <div className="col-md-12">
                  <div className="panel-group" id="accordion_10" role="tablist" aria-multiselectable="true">
                    <div className="panel panel-col-blue-grey">
                      <div className="panel-heading" role="tab" id="headingOne_10">
                      </div>
                      <div id="collapseOne_10" className="panel-collapse collapse in show" role="tabpanel" aria-labelledby="headingOne_10">
                        <div className="panel-body" >

                          <img src={props.url} className='rounded mx-auto d-block'></img>

                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div >
      </section>
    </>
  )
}
