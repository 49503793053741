import React, { useState, useEffect } from 'react';
import { useMap } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet-ruler';
import 'leaflet-ruler/src/leaflet-ruler.css';

export default function RulerComponent() {
  const map = useMap();
  useEffect(() => {

    if (!map)
      return;

    L.control.ruler().addTo(map);

  }, [map]);

  return null;
}