import React, { Component } from 'react'
import MenuCatalog from './MenuCalalog'

export default function LeftSidebar() {

  return (
    <>
      {/* <!-- Left Sidebar --> */}

      <aside id="leftsidebar" className="sidebar" style={{ zIndex: 1000 }}>
        {/* <!-- User Info --> */}
        <div className="user-info">
          <div className="image"> <img src="assets/images/xs/iede_logo_bg_white.png" width="48" height="48" alt="User" /></div>
          <div className="info-container">
            <div className="name"><span style={{ fontSize: '11px' }}> INFRAESTRUTURA ESTADUAL </span></div>
            {/* <div class="btn-group user-helper-dropdown">
              <i class="material-icons" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true" role="button"> keyboard_arrow_down </i>
              <ul class="dropdown-menu slideUp">
                <li><a href="#"><i class="material-icons">person</i>Profile</a></li>
                <li class="divider"></li>
                <li><a href="#"><i class="material-icons">group</i>Followers</a></li>
                <li><a href="#"><i class="material-icons">shopping_cart</i>Sales</a></li>
                <li><a href="#"><i class="material-icons">favorite</i>Likes</a></li>
                <li class="divider"></li>
                <li><a href="#"><i class="material-icons">input</i>Sign Out</a></li>
              </ul>
            </div> */}
          <div className="email"> <span style={{ fontSize: '11px' }}> DE DADOS ESPACIAIS</span></div>
          </div>
        </div>
        {/* <!-- #User Info --> */}
        {/* <!-- Menu --> */}
        <MenuCatalog />
      </aside>


    </>
  )

}