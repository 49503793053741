import React, { useEffect } from 'react'
import { useMap } from 'react-leaflet';

export default function InitialPositionButton(props) {
  const map = useMap();
  // const initialPostion = [process.env.REACT_APP_MAP_INITIALPOSITION_LATITUDE, process.env.REACT_APP_MAP_INITIALPOSITION_LONGITUDE]

  useEffect(() => {
    fly();
  }, [props.zoom])


  const fly = () => {
    map.flyTo(props.position, props.zoom);
  }

  return (
    <>
      <div className='leaflet-top leaflet-right' style={{ marginTop: 220, zIndex: 400 }} >
        <div className="leaflet-bar leaflet-control">
          <a id='buttonFly' className="zmdi zmdi-my-location leaflet-bar-part" href='javascript:void(0)' title="Centralizar" onClick={() => fly()}></a>
        </div>
      </div>
    </>
  );
}
