import { latLng } from 'leaflet'
import React, { useState, useEffect } from 'react'
import ContentBox from '../components/ContentBox'

export default function Documents() {

  const googleDriveUrlMapas = `https://drive.google.com/embeddedfolderview?id=${process.env.REACT_APP_DOCUMENTS_GOOGLEDRIVE_MAPAS}#`;
  const googleDriveUrlDocumentos = `https://drive.google.com/embeddedfolderview?id=${process.env.REACT_APP_DOCUMENTS_GOOGLEDRIVE_DOCUMENTOS}#`;

  return (
    <>
      <section className='content' style={{ marginBottom: '-80px' }}>
        <div className='block-header'>
          <div className='row'>
            <div className='col-lg-7 col-md-6 col-sm-12'>
              <h2> DOCUMENTOS  DISPONIBILIZADOS PELA IEDE </h2><small className='text-muted'> DOCUMENTOS PÚBLICOS </small>
            </div>
          </div>
        </div>
      </section>
      <ContentBox>

        <div className="row clearfix">
          <div className="col-sm-12 col-md-12 col-lg-12">
            <div className="card">
              <div className="header">
                <h2><i className='zmdi zmdi-map'></i> MAPAS <small> </small> </h2>
              </div>
              <div className="body table-responsive members_profiles">
                <iframe src={googleDriveUrlMapas + 'grid'} style={{ width: '100%', height: '520px', border: '0' }}></iframe>
              </div>
            </div>
          </div>
        </div>
        <div className="row clearfix">
          <div className="col-sm-12 col-md-12 col-lg-12">
            <div className="card">
              <div className="header">
                <h2><i className='zmdi zmdi-file-text'></i> DOCUMENTOS <small> </small> </h2>
              </div>
              <div className="body table-responsive members_profiles">
                <iframe src={googleDriveUrlDocumentos + 'list'} style={{ width: '100%', height: '350px', border: '0' }}></iframe>
              </div>
            </div>
          </div>
        </div>

        <p>
          <a href='https://fjp.mg.gov.br/informacoes-territoriais/' className='m-l-40 ' target='_blank' style={{ color: 'black' }} >
            <i className='zmdi zmdi-file-text m-r-10'> </i>  Áreas e Leis de Criação de Distritos e Municípios - Atualizado </a>
        </p>
        {/* <hr /> */}
      </ContentBox>
    </>
  )
}
