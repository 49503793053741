import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import "./Navbar.css"

export default class Navbar extends Component {
  handlerToggleMenu() {
    if (document.getElementById('toggle-menu').className == 'theme-blush')
      document.getElementById('toggle-menu').className = 'theme-blush ls-toggle-menu';
    else
      document.getElementById('toggle-menu').className = 'theme-blush';
  }
  render() {
    return (
      <>
        {/* <!-- Top Bar --> */}
        <nav className="navbar" style={{ zIndex: 1000 }}>
          <div className="col-12">

            <div className="navbar-header">
              <a href="javascript:void(0);" className="bars"></a>
              <Link className="navbar-brand" to='/'>IEDE-MG</Link>
            </div>

            <ul className="nav navbar-nav navbar-left">
              <li className="">
                <a href='javascript:void(0);'
                  onClick={() => this.handlerToggleMenu()}
                  className="ls-toggle-btn"
                  data-close="true">
                  <i className="zmdi zmdi-swap"></i>
                </a>
              </li>
              <li><Link to="/" className=""><i className="zmdi zmdi-home">
                <span className="spanSmall"> HOME</span></i> </Link>
              </li>
              {/* <li><Link to="about" className="" data-close="true"><i
                className="zmdi zmdi-help"> <span className="spanSmall"> SOBRE</span> </i> </Link>
              </li> */}

              {/* <li><a href="texto_home/sobre.html" target="mapas" className="inbox-btn hidden-sm-down" data-close="true"><i
                className="zmdi zmdi-google-maps"><span className="spanSmall"> MAPAS E DOCUMENTOS</span> </i>
              </a>
              </li> */}
            </ul>
            <ul className="nav navbar-nav navbar-right">
              {/* <li><a className="mega-menu xs-hide" data-close="true"><i className="zmdi zmdi-power"></i></a></li> */}
              {/* <li><Link to="https://fjp.mg.gov.br/fale-conosco/" target={'_blank'} data-close="true"><i
                className="zmdi zmdi-phone-in-talk"> <span className="spanSmall"> CONTATO </span> </i> </Link>
              </li> */}
              {/* <li><Link to="https://fjp.mg.gov.br/politica-de-privacidade/" target={'_blank'} data-close="true"><i
                className="zmdi zmdi-shield-check"> <span className="spanSmall"> PRIVACIDADE</span> </i> </Link>
              </li> */}

              <li class="dropdown"><a href="javascript:void(0);" class="dropdown-toggle xs-hide" style={{ textDecoration: 'none' }} data-toggle="dropdown" role="button"><i class="zmdi zmdi-info-outline"></i> <span className='spanSmall'>INFORMAÇÕES</span>
                <div class="notify"><span class="heartbit"></span><span class="point"></span></div>
              </a>
                <ul class="dropdown-menu slideDown">
                  <li class="header text-muted">INFORMAÇÕES IMPORTANTES</li>
                  <li class="body">
                    <ul class="menu list-unstyled">
                      <li><a href="https://fjp.mg.gov.br/fale-conosco/" target={'_blank'}>
                        <div class="icon-circle l-green"> <i class="zmdi zmdi-phone-in-talk"></i></div>
                        <div class="menu-info">
                          <h4>Fale Conosco</h4>
                          <p> Formas de contato </p>
                        </div>
                      </a></li>
                      <li><a href="https://fjp.mg.gov.br/politica-de-privacidade/" target={'_blank'}>
                        <div class="icon-circle l-turquoise"> <i class="zmdi zmdi-shield-check"></i> </div>
                        <div class="menu-info">
                          <h4>Privacidade</h4>
                          <p>  Nossas políticas </p>
                        </div>
                      </a></li>
                      <li>
                        <Link to={'/staff'}>
                          <div class="icon-circle l-amber"> <i class="zmdi zmdi-help"></i> </div>
                          <div class="menu-info">
                            <h4>Sobre</h4>
                            <p>  Sobre o WebGIS </p>
                          </div>
                        </Link>
                      </li>
                    </ul>
                  </li>
                  <li class="footer"><a href="javascript:void(0);"></a></li>
                </ul>
              </li>
            </ul>
          </div>
        </nav>
      </>
    )
  }
}
